import 'zone.js';
import * as singleSpa from 'single-spa';
import { GlobalEventDistributor } from './globalEventDistributor';
import { loadApp } from './helper';


window.$ = window.jQuery = require('jquery')

async function init() {
    const globalEventDistributor = new GlobalEventDistributor();
    const loadingPromises = [];

    // authportal: The URL "/authportal/..." is being redirected to "http://localhost:9001/..." this is done by the webpack proxy (webpack.config.js)
    loadingPromises.push(loadApp('home', '/home', '/home/singleSpaEntry.js', null, globalEventDistributor)); // does not have a store, so we pass null

    // authportal: The URL "/authportal/..." is being redirected to "http://localhost:9002/..." this is done by the webpack proxy (webpack.config.js)
    loadingPromises.push(loadApp('authportal', '/authportal', '/authportal/singleSpaEntry.js', null, globalEventDistributor)); // does not have a store, so we pass null

    // bot: The URL "/bot/..." is being redirected to "http://localhost:9004/..." this is done by the webpack proxy (webpack.config.js)
    loadingPromises.push(loadApp('bot', '/bot', '/bot/singleSpaEntry.js', null, globalEventDistributor)); // does not have a store, so we pass null

    // app5: The URL "/app5/..." is being redirected to "http://localhost:9005/..." this is done by the webpack proxy (webpack.config.js)
    loadingPromises.push(loadApp('app5', '/app5', '/app5/singleSpaEntry.js', null, globalEventDistributor)); // does not have a store, so we pass null

    // wait until all stores are loaded and all apps are registered with singleSpa
    await Promise.all(loadingPromises);

    singleSpa.start();
}

init();

